import React from 'react';

interface Props {
   className?: string;
}

export const PhoneIcon: React.FC<Props> = ({ className }) => {
   return (
      <svg className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
         <path d="M15.6009 16.5509L17.4608 14.6902C17.7114 14.4427 18.0283 14.2733 18.3732 14.2025C18.7181 14.1317 19.0762 14.1626 19.4038 14.2915L21.6707 15.1969C22.0018 15.3314 22.2858 15.5609 22.4867 15.8566C22.6877 16.1522 22.7967 16.5008 22.8 16.8583V21.0117C22.798 21.2549 22.7469 21.4952 22.6497 21.7181C22.5525 21.941 22.4112 22.142 22.2343 22.3088C22.0575 22.4757 21.8487 22.605 21.6205 22.689C21.3924 22.773 21.1496 22.81 20.9068 22.7976C5.02229 21.8091 1.81716 8.35213 1.21101 3.20192C1.18287 2.94901 1.20858 2.693 1.28644 2.45075C1.36431 2.20849 1.49256 1.98547 1.66277 1.79636C1.83298 1.60725 2.04128 1.45634 2.27398 1.35355C2.50668 1.25076 2.75849 1.19842 3.01286 1.19999H7.02342C7.3813 1.20104 7.73069 1.30919 8.02663 1.51052C8.32258 1.71184 8.55155 1.99714 8.6841 2.32971L9.58918 4.59746C9.72224 4.92397 9.75619 5.28247 9.68679 5.62818C9.61738 5.97388 9.44769 6.29146 9.19892 6.54125L7.33895 8.40197C7.33895 8.40197 8.41009 15.6538 15.6009 16.5509Z" />
      </svg>
   );
};
