import React from 'react';

interface Props {
   className?: string;
}

export const MenuIcon: React.FC<Props> = ({ className }) => {
   return (
      <svg className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
         <path d="M1 3.375C1 3.01033 1.14487 2.66059 1.40273 2.40273C1.66059 2.14487 2.01033 2 2.375 2H21.625C21.9897 2 22.3394 2.14487 22.5973 2.40273C22.8551 2.66059 23 3.01033 23 3.375C23 3.73967 22.8551 4.08941 22.5973 4.34727C22.3394 4.60513 21.9897 4.75 21.625 4.75H2.375C2.01033 4.75 1.66059 4.60513 1.40273 4.34727C1.14487 4.08941 1 3.73967 1 3.375Z" />
         <path d="M1 19.875C1 19.5103 1.14487 19.1606 1.40273 18.9027C1.66059 18.6449 2.01033 18.5 2.375 18.5H21.625C21.9897 18.5 22.3394 18.6449 22.5973 18.9027C22.8551 19.1606 23 19.5103 23 19.875C23 20.2397 22.8551 20.5894 22.5973 20.8473C22.3394 21.1051 21.9897 21.25 21.625 21.25H2.375C2.01033 21.25 1.66059 21.1051 1.40273 20.8473C1.14487 20.5894 1 20.2397 1 19.875Z" />
         <path d="M10.625 10.25C10.2603 10.25 9.91059 10.3949 9.65273 10.6527C9.39487 10.9106 9.25 11.2603 9.25 11.625C9.25 11.9897 9.39487 12.3394 9.65273 12.5973C9.91059 12.8551 10.2603 13 10.625 13H21.625C21.9897 13 22.3394 12.8551 22.5973 12.5973C22.8551 12.3394 23 11.9897 23 11.625C23 11.2603 22.8551 10.9106 22.5973 10.6527C22.3394 10.3949 21.9897 10.25 21.625 10.25H10.625Z" />
      </svg>
   );
};
