import React from 'react';

interface Props {
   className?: string;
}

export const CrossIcon: React.FC<Props> = ({ className }) => {
   return (
      <svg className={className} viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
         <path d="M4.22411 2.03201L12.0001 9.80801L19.7441 2.06401C19.8798 1.91955 20.0433 1.804 20.2248 1.72427C20.4062 1.64453 20.6019 1.60227 20.8001 1.60001C21.2245 1.60001 21.6314 1.76858 21.9315 2.06864C22.2315 2.36869 22.4001 2.77566 22.4001 3.20001C22.4038 3.39617 22.3675 3.59103 22.2932 3.77262C22.2189 3.95421 22.1083 4.1187 21.9681 4.25601L14.1441 12L21.9681 19.824C22.2318 20.082 22.3865 20.4313 22.4001 20.8C22.4001 21.2244 22.2315 21.6313 21.9315 21.9314C21.6314 22.2314 21.2245 22.4 20.8001 22.4C20.5962 22.4085 20.3928 22.3744 20.2027 22.3001C20.0126 22.2257 19.8401 22.1126 19.6961 21.968L12.0001 14.192L4.24011 21.952C4.10492 22.0916 3.94342 22.2031 3.76492 22.28C3.58642 22.3569 3.39446 22.3977 3.20011 22.4C2.77577 22.4 2.3688 22.2314 2.06874 21.9314C1.76868 21.6313 1.60011 21.2244 1.60011 20.8C1.59638 20.6038 1.63278 20.409 1.70707 20.2274C1.78135 20.0458 1.89197 19.8813 2.03211 19.744L9.85611 12L2.03211 4.17601C1.76841 3.91802 1.61378 3.56867 1.60011 3.20001C1.60011 2.77566 1.76868 2.36869 2.06874 2.06864C2.3688 1.76858 2.77577 1.60001 3.20011 1.60001C3.58411 1.60481 3.95211 1.76001 4.22411 2.03201Z" />
      </svg>
   );
};
